import { Anchor, Button } from "grommet";
import styled from "styled-components";

export const NavLink = styled(Anchor)`
  display: flex;
  padding: 12px;
`;

export const NavLinkButton = styled(Button)`
  display: flex;
`;


import React, { useState } from "react";
import {
  Box,
  Button,
  Layer,
  Heading,
  TextInput,
  Form,
  FormField,
} from "grommet";
import { Close } from "grommet-icons";
import { createShoppinglist } from "./service";

const CreateListModal = (props) => {
  const [show, setShow] = useState();

  const [formValues, setFormValues] = useState({
    name: "",
  });

  const onChange = (values) => {
    setFormValues(values);
  };

  const onSubmit = async ({ value, touched }) => {
    try {
      console.log(value)
      const res = await createShoppinglist(value.name);
      setShow(false)
      if (props.onCreated) {
        const onCreatedRes = props.onCreated({
          id: res.id,
          name: value.name,
        });
        if (onCreatedRes === false) {
          return;
        }
      }
      window.location.href = "/shoppinglists/" + res.id
    } catch (error) {
      console.log(error)
    }
  };


  return (
    <Box>
      <Button label="Skapa lista" onClick={() => setShow(true)} />
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
          background="background-front"
        >
          <Box pad="medium" overflow="auto">
            <Box direction="row" justify="end">
              <Button secondary icon={<Close />} onClick={() => setShow(false)} />
            </Box>
            <Heading margin="small" level="3" as="h1">
              Skapa lista
            </Heading>
            <Form
              validate="blur"
              value={formValues}
              messages={{
                required: "Detta fält är obligatoriskt",
              }}
              onChange={(nextValue) => onChange(nextValue)}
              onSubmit={({ value, touched }) => onSubmit({ value, touched })}
              method="post"
            >
              <FormField
                label="Listans namn"
                htmlFor="list-name"
                name="name"
                required={{ indicator: true }}
              >
                <TextInput placeholder="Namn" name="name" />
              </FormField>
              <Box direction="row" justify="end" gap="small" margin="small">
                <Button
                  label="Avbryt"
                  onClick={() => setShow(false)}
                />
                <Button label="Spara" type="submit" primary />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
    </Box>
  );
};

export default CreateListModal;
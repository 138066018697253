import { Box, Menu, Text } from "grommet";
import { Configure, Logout, User } from "grommet-icons";
import theme from "../theme";
import { useLogout } from "../auth/logout";
import { useUserData } from "../auth/useUserData";
import { useSessionData } from "../auth/useSessionData";

export const NavbarAccount = () => {
  const { email } = useUserData();
  const { user }= useSessionData();
  const { logout } = useLogout();
  return (
    <Menu
      style={{ position: "relative" }}
      label={
        <Box as="span" direction="row" align="center" justify="end" gap="small">
          <User color={theme.global.colors['accent-1']} />
          <Text truncate size="small" weight="bold" color={theme.global.colors['accent-1']} style={{ maxWidth: "200px" }}>{email}</Text>
        </Box>
      }
      icon={false}
      items={[
        ...user?.is_admin ? [{
          icon: <Configure />,
          label: "Admin",
          href: "/admin",
          gap: "small",
        }] : [],
        {
          icon: <Logout />,
          label: "Logga ut",
          onClick: logout,
          gap: "small",
        },
        {
          href: "/profile",
          icon: <User />,
          label: "Profil",
          gap: "small",
        },
      ]}
    />
  );
};

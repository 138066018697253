import { Anchor, Box, Button, Card, Grid, Heading, Text } from "grommet";
import React, { useEffect, useState } from "react";
import AddRecipe from "../recipe/addRecipeModal";
import { Page } from "../shared/Page";
import CreateListModal from "../shoppinglist/createListModal";
import { getShoppinglists } from "../shoppinglist/service";
import Responsive from "../util/responsive";

const HomeAuthorized = () => {
  const [shoppinglists, setShoppinglists] = useState([]);

  useEffect(() => {
    getShoppinglists().then((resp) => {
      setShoppinglists(resp);
    });
  }, []);

  return (
    <Page>
      <Box gap="xlarge">
        <Box gap="medium" align="start">
          <Heading as="h1" level="2" margin="none">Recept</Heading>
          <Text>Bläddra bland alla recept som finns inlagda i Gurkans databas, eller lägg till nya!</Text>
          <Box direction="row" gap="small">
            <Button
              primary
              label="Alla recept"
              href="/recipes"
            />
            <AddRecipe label="Lägg till" />
          </Box>
        </Box>
        <Box gap="medium" align="start">
          <Heading as="h1" level="2" margin="none">Inköpslistor</Heading>
          {shoppinglists.length > 0 ? (
            <Responsive>
              {({ isMobile, isPortrait }) => (
                <Grid
                  fill
                  columns={isMobile ? ["1fr"] : (isPortrait ? ["1fr", "1fr"] : ["1fr", "1fr", "1fr"])}
                  gap="small"
                >
                  {shoppinglists.slice(0, 3).map((element) => {
                    return (
                      <Anchor href={`/shoppinglists/${element.id}`} key={element.id}>
                        <Card background="background-contrast" pad="medium">
                          <Text color="text">{element.name}</Text>
                        </Card>
                      </Anchor>
                    );
                  })}
                </Grid>
              )}
            </Responsive>
          ) : (
            <Text>Du har inga inköpslistor.</Text>
          )}
          {shoppinglists.length > 3 && (
            <Anchor href="/shoppinglists">Se alla inköpslistor</Anchor>
          )}
          <CreateListModal />
        </Box>
      </Box>
    </Page>
  );
};

export default HomeAuthorized;

import { ResponsiveContext } from "grommet";

function Responsive({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {(responsive) => children(
        {
          isSmall: ['xsmall', 'small'].includes(responsive),
          isMobile: ['xsmall', 'small', 'medium'].includes(responsive),
          isPortrait: ['xsmall', 'small', 'medium', 'large'].includes(responsive),
        },
        responsive,
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Responsive;

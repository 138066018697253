import React, { useEffect, useState } from "react";
import { getShoppinglists } from "./service";
import { Anchor, Box, Card, Grid, ResponsiveContext, Text } from "grommet";
import CreateListModal from "./createListModal";
import { Page } from "../shared/Page";

const ShoppinglistList = (props) => {
  const [shoppinglists, setShoppinglists] = useState([]);

  useEffect(() => {
    getShoppinglists().then((resp) => {
      console.log(resp);
      setShoppinglists(resp);
    });
  }, []);

  return (
    <Page>
      <ResponsiveContext.Consumer>
        {(size) => (
          <>
            <Box
              direction={["xsmall", "small"].includes(size) ? "column" : "row"}
              gap="small"
              align={["xsmall", "small"].includes(size) ? "start" : "center"}
              margin={{ bottom: ["xsmall", "small"].includes(size) ? "large" : "" }}
            >
              <h1>Inköpslistor</h1>
              <CreateListModal />
            </Box>
            <Grid
              columns={{
                "xsmall": ["1fr"],
                "small": ["1fr"],
                "medium": ["1fr", "1fr"],
                "large": ["1fr", "1fr", "1fr"],
                "xlarge": ["1fr", "1fr", "1fr", "1fr"],
                "xxlarge": ["1fr", "1fr", "1fr", "1fr"],
              }[size]}
              gap="small"
            >
              {shoppinglists.map((element) => {
                return (
                  <Anchor href={`/shoppinglists/${element.id}`} key={element.id}>
                    <Card background="background-contrast" pad="medium">
                      <Text color="text">{element.name}</Text>
                    </Card>
                  </Anchor>
                );
              })}
            </Grid>
          </>
        )}
      </ResponsiveContext.Consumer>
    </Page>
  );
};

export default ShoppinglistList;
import { BASE_URL, CommonHeaders } from "../util/service";

const createShoppinglist = async (name) => {
  const shoppinglist = await fetch(BASE_URL + "shoppinglist", {
    method: "POST",
    headers: CommonHeaders(),
    body: JSON.stringify({ name: name }),
  })
    .then((resp) => resp.json());
  return shoppinglist;
}

const getShoppinglists = async () => {
  const shoppinglists = await fetch(BASE_URL + "shoppinglist", {
    method: "GET",
    headers: CommonHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => resp.lists);
  return shoppinglists;
};

const getShoppinglist = async (id) => {
  const shoppinglist = await fetch(BASE_URL + "shoppinglist/" + id, {
    method: "GET",
    headers: CommonHeaders(),
  })
    .then((resp) => resp.json())
  return shoppinglist;
};

const addIngredientsToList = async (shoppinglistId, ingredients) => {
  const promises = ingredients.map((ingredient) => {
    return fetch(BASE_URL + "shoppinglist/" + shoppinglistId + "/item", {
      method: "POST",
      headers: CommonHeaders(),
      body: JSON.stringify({
        ingredientId: ingredient.id,
        amount: ingredient.quantity,
        unitId: ingredient.default_unit.id,
        isMarked: false,
      }),
    });
  });
  return Promise.all(promises);
};

const updateListItem = async (shoppinglistId, itemId, isMarked, amount) => {
  await fetch(BASE_URL + "shoppinglist/" + shoppinglistId + "/item", {
    method: "PATCH",
    headers: CommonHeaders(),
    body: JSON.stringify({
      ingredientId: itemId,
      isMarked: isMarked,
      amount: amount,
    }),
  })
}

export { createShoppinglist, getShoppinglists, getShoppinglist, addIngredientsToList, updateListItem};

import { BASE_URL, CommonHeaders } from "../util/service";

const getUnapprovedIngredients = async () => {
  const res = await fetch(BASE_URL + "ingredient/unapproved", {
    method: "GET",
    headers: CommonHeaders(),
  })
  if (!res.ok) {
    throw new Error("Failed to get unapproved ingredients")
  }

  const jsonIngredients = await res.json()
  const ingredients = jsonIngredients.ingredients
  return ingredients;
}

const approveIngredient = async (id) => {
  const res = await fetch(BASE_URL + "ingredient/" + id + "/approve", {
    method: "PATCH",
    headers: CommonHeaders(),
  })
  if (!res.ok) {
    throw new Error("Failed to approve ingredient")
  }
}

const rejectIngredient = async (id) => {
  const res = await fetch(BASE_URL + "ingredient/" + id + "/reject", {
    method: "PATCH",
    headers: CommonHeaders(),
  })
  if (!res.ok) {
    throw new Error("Failed to reject ingredient")
  }
}

const updateIngredient = async (id, name, density, parentId, defaultUnitId) => {
  const res = await fetch(BASE_URL + "ingredient/" + id, {
    method: "PUT",
    headers: CommonHeaders(),
    body: JSON.stringify({ name, density, parentId, defaultUnitId })
  })
  if (!res.ok) {
    throw new Error("Failed to update ingredient")
  }
  return res.body;
}

export { getUnapprovedIngredients, approveIngredient, rejectIngredient, updateIngredient }
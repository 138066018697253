import { Box, Button, Grid, Heading, Image, Text } from "grommet";
import React from "react";
import { Page } from "../shared/Page";
import Responsive from "../util/responsive";

const Home = () => {
  return (
    <Page>
      <Responsive>
        {({ isPortrait }) => (
          <Grid
            rows={isPortrait ? ['auto', 'auto'] : ['auto']}
            columns={isPortrait ? ['1fr'] : ['1fr', '1fr']}
            gap="large"
            areas={[
              { name: 'text', start: [0, 0], end: [0, 0] },
              isPortrait ?
                { name: 'image', start: [0, 1], end: [0, 1] }:
                { name: 'image', start: [1, 0], end: [1, 0] },
            ]}
          >
            <Box gridArea="text" align="start" direction="column" gap="medium">
              <Heading margin={{ top: "large", bottom: "none" }}>Välkommen till Gurkan</Heading>
              <Text>Lägg in dina recept eller importera från ICA, köket eller Arla.</Text>
              <Button
                href="/login"
                label="Logga in"
                hoverIndicator
              />
            </Box>
            <Box gridArea="image" style={{overflow: "hidden", borderRadius: "20px"}}>
              <Image fit="cover" src="/pexels-esra-korkmaz-14677945.jpg" style={{aspectRatio: "3/2"}} />
            </Box>
          </Grid>
        )}
      </Responsive>
    </Page>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { approveIngredient, getUnapprovedIngredients, rejectIngredient } from "./service";
import { Image, Box, Heading, ResponsiveContext, Card, CardBody, Text, Button, Grid } from "grommet";
import { Page } from "../shared/Page";
import { Close, Edit, Return, Revert, Validate } from "grommet-icons"
import { EditIngredientModal } from "./editIngredientModal";
import styled from "styled-components";

const FlippedReturn = styled(Return)`
  transform: scaleX(-1);
`;

const ValidateIngredients = (props) => {
  const [ingredients, setIngredients] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedIngredient, setSelectedIngredient] = useState(null)

  useEffect(() => {
    getUnapprovedIngredients().then((ingredients) => {
      setIngredients(ingredients);
    });
  }, []);

  const handleEdit = (item) => {
    setShowEditModal(true);
    setSelectedIngredient(item);
  }

  const handleApprove = (item) => {
    approveIngredient(item.id).then(() => {
      setIngredients(ingredients.filter((i) => i.ingredient.id != item.id));
    });
  }

  const handleReject = async (item) => {
    try {
      await rejectIngredient(item.id)
      setIngredients(ingredients.filter((i) => i.ingredient.id != item.id));
    } catch (error) {
      console.log("Failed to reject ingredient", error)
    }
  }

  const handleOnEditModalClosed = () => {
    setShowEditModal(false);
    getUnapprovedIngredients().then((ingredients) => {
      setIngredients(ingredients);
    });
  }

  return (
    <ResponsiveContext.Consumer>
      {() => (
        <Box gridArea="content" pad={{ "bottom": "xlarge" }}>
          <Page>
            <Heading level="2" as="h1">Validera ingredienser</Heading>
            {ingredients && ingredients.size != 0 && ingredients.map((ingredient, id) => (
              <Card background="brand" margin="small" height={"240px"} width="large" key={`validate-card-${id}`}>
                <CardBody pad="medium">
                  <Grid
                    justify="start"
                    columns={['auto', 'flex', 'auto']}
                    rows={['auto']}
                    areas={[
                      {name: 'photo', start: [0,0], end: [0,0]},
                      {name: 'text', start: [1,0], end: [1,0]},
                      {name: 'buttons', start: [2,0], end: [2,0]},
                    ]}>
                    <Box gridArea="photo" height="small" width="small">
                      <Image
                        style={{borderRadius: "10px"}}
                        fit="cover"
                        src="https://picsum.photos/400"
                      />
                    </Box>
                    <Box gridArea="text" direction="column" pad={{horizontal:"medium"}}>
                      <Heading level={4} margin={{bottom:"0"}}>{ingredient.parent.name}</Heading>
                      <Heading level={3} margin={{top:"0"}}>
                        {ingredient.parent.name && <FlippedReturn />}
                        {ingredient.ingredient.name}
                      </Heading>
                      <Text>Enhet: {ingredient.ingredient.default_unit.name} ({ingredient.ingredient.default_unit.suffix})</Text>
                      <Text>Densitet: {ingredient.ingredient.density}</Text>
                      <Text>Skapare: {ingredient.owner.display_name}</Text>
                    </Box>
                    <Box gridArea="buttons" direction="row" align="start">
                      <Button icon={<Edit size="large" color="accent-1"/>} onClick={() => handleEdit(ingredient)} />
                      <Button icon={<Close size="large" color="accent-1"/>} onClick={() => handleReject(ingredient.ingredient)} />
                      <Button icon={<Validate size="large" color="accent-1" />} onClick={() => handleApprove(ingredient.ingredient)} />
                    </Box>
                  </Grid>
                </CardBody>
              </Card>
            ))}
            {(!ingredients || ingredients.size == 0) && <Heading level="3" as="h2">Inga ingredienser att validera</Heading>}
          </Page>
          {showEditModal && <EditIngredientModal ingredient={selectedIngredient.ingredient} parent={selectedIngredient.parent} onClose={handleOnEditModalClosed}/>}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
};

export default ValidateIngredients;

import React, { useEffect, useState } from "react";
import { Box, Button, Card, Heading, Layer } from "grommet";
import { Close } from "grommet-icons";
import { getShoppinglists } from "../shoppinglist/service";
import CreateListModal from "../shoppinglist/createListModal";

export const ChooseListModal = (props) => {
  const [shoppinglists, setShoppinglist] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const list = await getShoppinglists();
      setShoppinglist(list);
    }
    fetchData();
  }, []);

  const onListCreated = (list) => {
    props.onClick(list);
    return false;
  }

  return (
    <Layer
      onEsc={() => props.onClose()}
      onClickOutside={() => props.onClose()}
      background="background-front"
    >
      <Box pad="medium" gap="medium">
        <Box direction="row" justify="end">
          <Button secondary icon={<Close />} onClick={() => props.onClose()} />
        </Box>
        <Heading level="3" as="h1">Välj inköpslista</Heading>
        <Box gap="small">
          {shoppinglists && shoppinglists.map((element) => {
            return (
              <Button onClick={() => props.onClick(element)} key={element.id} hoverIndicator>
                <Card pad="small">{element.name}</Card>
              </Button>
            )
          })}
        </Box>
        <CreateListModal
          onCreated={onListCreated}
        />
      </Box>
    </Layer>
  );
}
import { useState, useEffect, useMemo } from "react";
import { Hanko } from "@teamhanko/hanko-elements";
import { getUser } from "./service";

const hankoApi = process.env.REACT_APP_HANKO_API_URL;

export function useSessionData() {
  const hanko = useMemo(() => new Hanko(hankoApi), []);
  const [sessionState, setSessionState] = useState({
    userID: "",
    jwt: "",
    user: null,
    isValid: false,
    loading: true,
    error: null,
  });

  const handleHankoSession = async () => {
    if (hanko) {
      const isValid = hanko.session.isValid();
      const session = hanko.session.get();

      if (isValid && session) {
        const { userID, jwt = "" } = session;
        const user = await getUser(userID)
        setSessionState({
          userID,
          jwt,
          isValid,
          user,
          loading: false,
          error: null,
        });
      } else {
        setSessionState((prevState) => ({
          ...prevState,
          isValid: false,
          loading: false,
          user: null,
          error: "Invalid session",
        }));
      }
    }

  }

  useEffect(() => {
    handleHankoSession()
  }, [hanko]);

  hanko.onAuthFlowCompleted(async (session) => {
    const { userID, jwt = "" } = session;
    const user = await getUser(userID)
    setSessionState({
      userID,
      jwt,
      isValid: true,
      loading: false,
      user,
      error: null,
    });
  });

  hanko.onUserLoggedOut(() => {
    setSessionState((prevState) => ({
      ...prevState,
      isValid: false,
      loading: false,
      user: null,
      error: "User logged out",
    }));
  });

  return sessionState;
}

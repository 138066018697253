import { useState, useEffect } from "react";
import { Box, TextInput } from "grommet"
import useDebounce from "../util/debounce";
import { searchIngredient } from "../recipe/service";

const IngredientSearchField = ({onIngredientSelected, onHasExactMatch, ...props}) => {
  const [searchValue, setSearchValue] = useState();
  const debouncedSearch = useDebounce(searchValue, 300);
  const [suggestions, setSuggestions] = useState([]);

  const _onHasExactMatch = (hasExactMatch) => {
    if (onHasExactMatch) {
      onHasExactMatch(hasExactMatch)
    }
  }

  useEffect(() => {
    if (debouncedSearch) {
      searchIngredient(debouncedSearch, 0.3).then((res) => {
        setSuggestions(res.ingredients);
        const hasExactMatch = res.ingredients.some(
          (v) => v.name.toLowerCase() === debouncedSearch.toLowerCase()
        );
        _onHasExactMatch(hasExactMatch)
      });
    } else {
      _onHasExactMatch(false);
    }
  }, [debouncedSearch, onHasExactMatch]);

  const onSuggestionSelect = (target) => {
    setSearchValue("");
    onIngredientSelected(target.suggestion.value);
  };

  return (
    <Box {...props}>
      <TextInput
        value={searchValue}
        placeholder="Sök efter en ingrediens"
        onChange={(e) => setSearchValue(e.target.value)}
        suggestions={suggestions.map((v) => ({
          label: v.name,
          value: v,
        }))}
        onSuggestionSelect={onSuggestionSelect}
      />
    </Box>
  );
}

export default IngredientSearchField;
import { Page as GrommetPage, PageContent as GrommetPageContent } from "grommet"

export function Page({ width, children }) {
  return (
    <GrommetPage width={{ max: width ?? "xlarge" }}>
      <GrommetPageContent pad="medium">
        {children}
      </GrommetPageContent>
    </GrommetPage>
  )
}

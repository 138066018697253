import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Hanko } from "@teamhanko/hanko-elements";
import { Logout } from "grommet-icons";
import { NavLink } from "../navbar/NavLink";

const hankoApi = process.env.REACT_APP_HANKO_API_URL;

export function useLogout() {
  const navigate = useNavigate();
  const hanko = useMemo(() => new Hanko(hankoApi), []);

  const logout = async (e) => {
    e.preventDefault();
    try {
      console.log("logout")
      await hanko?.user.logout();
      console.log("logout done")
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return { logout };
}

function LogoutBtn() {
  const { logout } = useLogout();

  return <NavLink
      href="/#"
      icon={<Logout />}
      label="Logga ut"
      onClick={logout}
      hoverIndicator
    />;
}

export default LogoutBtn;

import { Box, Card, Heading, CardHeader, CardBody, Button, Notification } from "grommet";
import { Add } from "grommet-icons";
import React, { useState } from "react";
import { ChooseListModal } from "./chooseListModal";
import { addIngredientsToList } from "../shoppinglist/service";
import theme from "../theme";

const IngredientList = (props) => {
  const [addToListToast, setAddToListToast] = useState(null);
  const [showListModal, setShowListModal] = useState(false);
  const [showOriginalCopy, setShowOriginalCopy] = useState({})

  const onAddIngredientsClicked = () => {
    setShowListModal(true);
  }

  const onListChosen = (list) => {
    setAddToListToast(list.name);
    setShowListModal(false);
    addIngredientsToList(list.id, props.ingredients);
  }

  const closeModal = () => {
    setShowListModal(false);
  }

  const handleIngredientClicked = (id, value) => {
    if (value === undefined) {
      setShowOriginalCopy({ ...showOriginalCopy, [id]: !showOriginalCopy[id] });
    } else {
      setShowOriginalCopy({ ...showOriginalCopy, [id]: value });
    }
  }

  const renderIngredient = (ingredient) => {
    if (showOriginalCopy[ingredient.id] && ingredient.original_copy){
      return <Box
        pad="xsmall"
        background={theme.global.colors["accent-1"]}
        gridArea="name"
      >
        {ingredient.original_copy}
      </Box>
    }

    var quantityUnit
    if (ingredient.default_unit.suffix === "") {
      quantityUnit = ""
    } else {
      quantityUnit = ingredient.quantity + " " + ingredient.default_unit.suffix
    }
    return <>
      <Box pad="xsmall" gridArea="name">{ingredient.name}</Box>
      <Box gridArea="quantity">
        {quantityUnit}
      </Box>
    </>
  }

  return (
    <div>
      {addToListToast && (
        <Notification
          toast
          status="normal"
          title="Ingredienser tillagda"
          message={`Lades till i listan "${addToListToast}"`}
          pad="medium"
          onClose={() => setAddToListToast(null)}
        />
      )}
      {showListModal && <ChooseListModal
        onClose={closeModal}
        onClick={onListChosen}
      />}
      <Card background="background-contrast">
        <CardHeader pad="small">
          <Heading level="3" margin="none">Ingredienser</Heading>
        </CardHeader>
        <CardBody pad="xsmall">
          {props.ingredients.map((ingr, index) => (
            <Box
              direction="row"
              margin="xsmall"
              pad={{"horizontal": "small", "vertical": 0}}
              justify="between"
              flex="grow"
              key={index}
              onTouchStart={() => handleIngredientClicked(ingr.id)}
              onMouseEnter={() => handleIngredientClicked(ingr.id, true)}
              onMouseLeave={() => handleIngredientClicked(ingr.id, false)}
            >
              {renderIngredient(ingr)}
            </Box>
          ))}
          <Button icon={<Add />} label="Lägg till i Inköpslista" onClick={() => onAddIngredientsClicked()} />
        </CardBody>
      </Card>
    </div>
  );
};

export default IngredientList;

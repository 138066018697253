import { grommet } from "grommet";
import { deepMerge, normalizeColor } from "grommet/utils";

const theme = deepMerge(grommet, {
  global: {
    breakpoints: {
      xsmall: {
        value: 375,
      },
      small: {
        value: 568,
        edgeSize: {
          none: '0px',
          small: '6px',
          medium: '12px',
          large: '24px',
        },
      },
      medium: {
        value: 768,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
      large: {
        value: 1024,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
      xlarge: {
        value: 1260,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
      xxlarge: {
        value: 1500,
        edgeSize: {
          none: '0px',
          small: '12px',
          medium: '24px',
          large: '48px',
        },
      },
    },
    colors: {
      brand: {
        light: '#CBF1B8',
        dark: '#26381e',
      },
      'accent-1': {
        light: '#1F5C03',
        dark: '#CBF1B8',
      },
      focus: '#FFD600',
      selected: 'accent-1',
      background: {
        light: '#ffffff',
        dark: '#222222',
      },
      "background-contrast": {
        light: '#ffffff',
        dark: '#333333',
      },
    },
    font: {
      family: 'Poppins, Roboto, Helvetica, Arial, sans-serif',
    },
    input: {
      weight: 400,
    },
  },
  button: {
    color: 'accent-1',
    border: {
      width: '2px',
      color: 'accent-1',
    },
  },
  anchor: {
    color: 'accent-1',
  },
  checkBox: {
    border: {
      color: {
        light: 'accent-1',
      },
      radius: '2px',
    },
    check: {
      extend: ({ theme: extendTheme, checked }) => `
        ${
          checked &&
          `
            background-color: ${normalizeColor('accent-1', extendTheme)};
            border-color: ${normalizeColor('accent-1', extendTheme)};
          `
        }
      `,
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    icon: {
      size: '18px',
      extend: ({ theme: extendTheme }) => `stroke: ${normalizeColor('background', extendTheme)};`,
    },
  },
  heading: {
    level: {
      1: {
        medium: {
          size: '3.4em',
          height: '1',
        },
      },
      2: {
        medium: {
          size: '2.8em',
          height: '1',
        },
      },
      3: {
        medium: {
          size: '1.8em',
          height: '1',
        },
      },
      4: {
        medium: {
          size: '1.2em',
          height: '1',
        },
      },
    },
  },
  page: {
    wide: {
      width: {
        min: "0px",
        max: "xxlarge",
      },
    },
  },
});

export default theme;

import { Box, Button, Card, Form, FormField, Heading, Layer, Text, TextInput, Select } from "grommet";
import { useState, useEffect, useCallback } from "react";
import { Close } from "grommet-icons";
import { updateIngredient } from "./service";
import { getUnits, createIngredient } from "../ingredient/service";
import IngredientSearchField from "../ingredient/search";
import { v4 as uuid } from "uuid";

export const EditIngredientModal = ({ isCreate, ingredient, parent, onClose }) => {
  const [units, setUnits] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [formValues, setFormValues] = useState({
    name: isCreate ? "" : ingredient.name,
    density: isCreate ? 0 : ingredient.density,
    parentID: isCreate ? "" : ingredient.parent_id,
    defaultUnitType: isCreate ? "" : ingredient.default_unit.unit_type.name,
    defaultUnit: isCreate ? "" : ingredient.default_unit,
  });
  const [error, setError] = useState(null);
  const [selectedIngredient, setSelectedIngredient] = useState(parent);

  const onChange = (values) => {
    setFormValues(values);
  };

  useEffect(() => {
    getUnits().then((units) => {
      setUnits(units.units);
      setUnitTypes(units.types);
    });
  }, []);

  const onIngredientSelected = (ingredient) => {
    formValues.parentID = ingredient.id;
    setSelectedIngredient(ingredient);
  }

  const unitOptions = useCallback(() => {
    if (unitTypes && units && formValues?.defaultUnitType) {
      return units
        .filter((unit) => unit.unit_type.name === formValues.defaultUnitType)
    }
    return [];
  }, [unitTypes, units, formValues]);

  const submitFunction = async (id, name, density, parentID, defaultUnitId) => {
    if (isCreate) {
      return await createIngredient(name, density, parentID, defaultUnitId);
    } else {
      return await updateIngredient(id, name, density, parentID, defaultUnitId);
    }
  }

  const onSubmit = async ({ value, touched }) => {
    setError(null);

    if (touched) {
      try {
        const res = await submitFunction(
          !isCreate && ingredient.id,
          value.name,
          value.density,
          value.parentID,
          value.defaultUnit.id,
        );

        if (res.error) {
          setError(res.error);
        } else {
          onClose(res);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}
      margin="medium"
    >
      <Card background="background-front" pad="medium" overflow="auto">
        <Box direction="row" justify="end">
          <Button secondary icon={<Close />} onClick={onClose} />
        </Box>
        <Heading margin="small" level="3" as="h1">
          {isCreate ? "Skapa" : "Redigera"} ingrediens
        </Heading>
        <Box gap="medium">
          <Form
            validate="blur"
            value={formValues}
            messages={{
              required: "This is a required field.",
            }}
            onChange={(nextValue) => onChange(nextValue)}
            onSubmit={({ value, touched }) => onSubmit({ value, touched })}
            method="post"
          >
            <FormField
              name="name"
              htmlFor="name"
              type="text"
              plain="true"
              label="Namn"
            >
              <TextInput
                type="text"
                placeholder="Gurka"
                name="name"
                plain="true"
              />
            </FormField>
            <FormField
              name="density"
              htmlFor="density"
              type="text"
              plain="true"
              label="Densitet (kg/l)"
            >
              <TextInput
                type="text"
                placeholder="0.1"
                name="density"
                plain="true"
              />
            </FormField>
            <FormField
              name="defaultUnitType"
              htmlFor="defaultUnitType"
              type="select"
              plain="true"
              label="Enhet"
            >
              <Select
                type="select"
                name="defaultUnitType"
                options={unitTypes ? [...unitTypes.map((unitType) => unitType.name)]: []}
              />
            </FormField>
            <FormField
              name="defaultUnit"
              htmlFor="defaultUnit"
              type="select"
              plain="true"
              label="Enhet"
            >
              <Select
                type="select"
                name="defaultUnit"
                valueKey="suffix"
                options={[...unitOptions()]}
              />
            </FormField>
            <Box margin="small">
              <Box margin={{vertical: "small"}}>
                <Text>Närmast överordnad ingrediens</Text>
              </Box>
              <Box pad={{vertical: "small"}}>
                {selectedIngredient ?
                  <Box direction="row" align="center">
                    <Text>{selectedIngredient.name}</Text>
                    <Button icon={<Close />} onClick={() => {
                      setSelectedIngredient(null)
                      setFormValues({ ...formValues, parentID: uuid.NIL })
                    }} />
                  </Box>
                  :
                  <Box pad={{vertical:"small"}}>
                    <Text>Ingen</Text>
                  </Box>
                }
              </Box>
              <IngredientSearchField
                onIngredientSelected={onIngredientSelected}
                />
            </Box>
            {error && (
              <Text color="status-error" as="p">{error}</Text>
            )}
            <Box direction="row" justify="end">
              <Button primary label={isCreate ? "Skapa ingrediens" : "Uppdatera ingrediens"} type="submit" />
            </Box>
          </Form>
        </Box>
      </Card>
    </Layer>
  );
};

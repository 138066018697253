import { useEffect } from "react";
import { register } from "@teamhanko/hanko-elements";
import { Page } from "../shared/Page";

const hankoApi = process.env.REACT_APP_HANKO_API_URL;

export default function HankoProfile() {
  useEffect(() => {
    register(hankoApi)
      .catch((error) => {
        console.error("failed to register hanko-profile component", error)
      });
  }, []);

  return (
    <Page>
      <hanko-profile api={hankoApi} />
    </Page>
  );
};

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const token = () => {
  const jwt = document.cookie
    .split("; ")
    .find((row) => row.startsWith("hanko="))
    ?.split("=")[1];

  return jwt;
};

const parsedToken = () => {
  const jwt = token();
  if (jwt == null) return null;

  const jwtParsed = parseJwt(jwt);
  if (new Date().getTime() / 1000 > jwtParsed.exp) {
    return null;
  }

  return jwtParsed;
};

export { parsedToken, token };

import { BASE_URL, CommonHeaders } from "../util/service";
import { v4 as uuid } from "uuid";

const createIngredient = async (name, density, parentId, defaultUnitId) => {
  if (!parentId) {
    parentId = uuid.NIL
  }
  try {
    const res = await fetch(BASE_URL + "ingredient", {
      method: "POST",
      headers: CommonHeaders(),
      body: JSON.stringify({ name: name, defaultUnitId: defaultUnitId, parentId: parentId, density: density}),
    })
    const jsonIngredient = await res.json()

    if (!res.ok) {
      throw new Error("Failed to create ingredient")
    }
    const ingredient = jsonIngredient.ingredient
    return ingredient;
  } catch (error) {
    console.log("error", error)
  }
}

const getUnits = async () => {
  const units = await fetch(BASE_URL + "ingredient/units", {
    method: "GET",
    headers: CommonHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => resp.units)
    .then((resp) => {
      var types = resp
        .map((unit) => unit.unit_type) // Get the unitType of each unit
        // Find unique unitTypes, I am aware this is not very readable but it is what it is
        .filter((self, index, array) => array.indexOf(array.find((elem) => elem.name === self.name)) === index)
      return {
        types: types,
        units: resp,
      }
    })
  return units;
}

export { createIngredient, getUnits }
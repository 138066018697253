import "./App.css";
import React from "react";
import RecipeList from "./recipe/recipeList";
import Recipe, { loader as recipeLoader } from "./recipe/recipe";
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import Navbar from "./navbar/Navbar";
import HomePage from "./home";
import { Grommet } from "grommet";
import HankoAuth from "./auth/login";
import HankoProfile from "./auth/profile";
import ShoppinglistList from "./shoppinglist/shoppinglistList";
import Shoppinglist, { loader as shoppinglistLoader } from "./shoppinglist/shoppinglist";
import theme from "./theme";
import ValidateIngredients from "./admin/admin";
import { SharedRecipe } from "./share/sharedRecipe";
import { AdminGuard } from "./util/adminGuard";
import { NotFound } from "./notFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navbar />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "/recipes",
        element: <RecipeList />,
      },
      {
        path: "/recipes/:id",
        element: <Recipe />,
        loader: recipeLoader,
      },
      {
        path: "/shoppinglists",
        element: <ShoppinglistList />,
      },
      {
        path: "/shoppinglists/:id",
        element: <Shoppinglist />,
        loader: shoppinglistLoader,
      },
      {
        path: "/shared-recipe",
        element: <SharedRecipe />,
      },
      {
        path: "/login",
        element: <HankoAuth />,
      },
      {
        path: "/profile",
        element: <HankoProfile />
      },
      {
        path: "/admin",
        element: <AdminGuard>
            <ValidateIngredients />,
          </AdminGuard>
      },
      {
        path: "*",
        element: <NotFound />,
      }
    ],
  },
]);

function App() {
  return (
    <Grommet full theme={theme} themeMode="auto">
          <RouterProvider router={router} />
    </Grommet>
  );
}

export default App;

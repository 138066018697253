import { useEffect } from "react"
import { useSessionData } from "../auth/useSessionData"
import { useNavigate } from "react-router-dom"

export const AdminGuard = ({ children}) => {
  const {user} = useSessionData()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !user.is_admin) {
      navigate("/404")
    }
  }, [user, navigate])

  if (user && user.is_admin) {
    return children
  }
}
import { Card, CardBody, Box, CardHeader, Heading, Grid, Menu, Text } from "grommet";
import { List as ListIcon, Grid as GridIcon } from "grommet-icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Responsive from "../util/responsive";

const STORAGE_KEY = "gurkan.layout";

const ListRow = styled(Grid)`
  border-bottom: 1px solid #ccc;
`;

const StepText = styled(Text)`
  line-height: 1;
`;

const ListLabel = () => (
  <Box direction="row" gap="small" align="center">
    <ListIcon />
    Visa som lista
  </Box>
);

const GridLabel = () => (
  <Box direction="row" gap="small" align="center">
    <GridIcon />
    Visa som rutnät
  </Box>
);

const Steps = (props) => {
  const [layout, setLayout] = useState();

  useEffect(() => {
    const storedLayout = sessionStorage.getItem(STORAGE_KEY);
    if (storedLayout) {
      setLayout(storedLayout);
    } else {
      setLayout("List");
    }
  }, []);

  useEffect(() => {
    if (layout) {
      sessionStorage.setItem(STORAGE_KEY, layout);
    }
  }, [layout]);

  return (
    <Box gap="medium">
      <Box direction="row" justify="between" align="center">
        <Heading level="3" margin="none">
          Steg
        </Heading>
          <Responsive>
            {({ isMobile }) => !isMobile && (
              <Menu
                label={layout === "List" ? (<ListLabel />) : (<GridLabel />)}
                items={[
                  { label: (<ListLabel />), onClick: () => setLayout("List") },
                  { label: (<GridLabel />), onClick: () => setLayout("Grid") },
                ]}
                icon={false}
              />
            )}
          </Responsive>
      </Box>
      {layout === "List" ? (
        <Box gap="large">
          {props.steps.map((step, index) => (
            <ListRow columns={["20px", "1fr"]} gap="medium" pad={{bottom: "small"}} key={index}>
              <StepText weight="bold" size="xlarge">{index + 1}.</StepText>
              <Text size="large">{step.description}</Text>
            </ListRow>
          ))}
        </Box>
      ) : (
        <Grid
          rows={["auto"]}
          columns={props.columns ?? ["1fr", "1fr"]}
          gap="medium"
        >
          {props.steps.map((step, index) => (
            <Card
              height="100%"
              background="light-1"
              key={index}
            >
              <CardHeader pad={{top: "medium", left: "medium", right: "medium"}}>
                <Text weight="bold" size="large">{index + 1}.</Text>
              </CardHeader>
              <CardBody pad={{top: "small", bottom: "medium", left: "medium", right: "medium"}}>
                <Text size="medium">{step.description}</Text>
              </CardBody>
            </Card>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Steps;

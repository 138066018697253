import React from "react";
import { getRecipe } from "./service";
import { useLoaderData } from "react-router-dom";
import IngredientList from "./ingredientList";
import Steps from "./steps";
import { Grid, Box, Heading, Anchor, Text, ResponsiveContext, Paragraph } from "grommet";
import { Clock, ShareRounded, User } from "grommet-icons";
import { Page } from "../shared/Page";
import Responsive from "../util/responsive";

export async function loader({ params }) {
  return getRecipe(params.id);
}

const Recipe = (props) => {
  const recipe = useLoaderData();
  return (
    <Box direction="column" align="center" gap="small" fill>
      <Box background="brand" align="center" fill>
        <Page width="xlarge">
          <Heading level="2" as="h1" margin={{bottom: 0}}>{recipe.name}</Heading>
          {recipe.original_link &&
            <Anchor href={recipe.original_link}>
              <Box direction="row" justify="start" align="center">
                  Orginalrecept
                <ShareRounded/>
              </Box>
            </Anchor>
          }
          <Responsive>
            {({ isPortrait }) => (
              <Box direction={isPortrait ? "column" : "row"} justify="between">
                <Paragraph>{recipe.description}</Paragraph>
                <Box alignSelf="end">
                  <Box direction="row" justify="end">
                    <Box pad="small">
                      <Anchor icon={<Clock />} label={Math.floor(recipe.time) + "m"} />
                    </Box>
                  </Box>
                  <Box direction="row" justify="end">
                    <Box pad="small">
                      <Anchor icon={<User />} label={recipe.yield} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Responsive>
        </Page>
      </Box>
      <Box pad={{ "bottom": "xlarge" }}>
        <Page>
          <ResponsiveContext.Consumer>
          {size => (
            <Grid
              rows={["auto"]}
              columns={["xsmall", "small", "medium"].includes(size) ? ["1"] : ["medium", "auto"]}
              gap="large"
              areas={["xsmall", "small", "medium"].includes(size) ? [
                ["ingredientList"],
                ["steps"],
              ] : [
                ["ingredientList", "steps"],
              ]}
            >
              <Box gridArea="ingredientList">
                <IngredientList ingredients={recipe.ingredients} />
              </Box>
              <Box gridArea="steps">
                <Steps steps={recipe.steps} columns={{
                  "xsmall": ["1fr"],
                  "small": ["1fr"],
                  "medium": ["1fr", "1fr"],
                  "large": ["1fr"],
                  "xlarge": ["1fr", "1fr"],
                  "xxlarge": ["1fr", "1fr", "1fr"],
                 }[size]} />
              </Box>
            </Grid>
          )}
          </ResponsiveContext.Consumer>
        </Page>
      </Box>
    </Box>
  );
};

export default Recipe;

export const AvailableSites = [
  {
    name: "Köket.se",
    url: "https://www.koket.se/",
  }, {
    name: "ICA",
    url: "https://www.ica.se/recept/",
  }, {
    name: "Arla",
    url: "https://www.arla.se/recept/"
  },
];

export const getAvailableSitesString = (divider = '&') => {
  const sites = AvailableSites.map((s) => s.name);
  if (sites.length === 1) {
    return sites[0];
  }
  return `${sites.slice(0, sites.length - 1).join(', ')} ${divider} ${sites[sites.length - 1]}`;
};
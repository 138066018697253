import { Box, Button, Card, Form, FormField, Heading, Layer, Text, TextInput } from "grommet";
import { useState } from "react";
import { importRecipeFromURL } from "./service";
import { token } from "../auth/util";
import { Close } from "grommet-icons";

export const ImportOrCreateRecipeModal = ({ onClose, onCreate }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    steps: [{ title: "", description: "" }],
    ingredients: [],
  });
  const [error, setError] = useState(null);

  const onCreateNewRecipe = () => {
    onCreate();
    onClose();
  };

  const onChange = (values) => {
    setFormValues(values);
  };

  const onSubmit = async ({ value, touched }) => {
    setError(null);
    try {
      const res = await importRecipeFromURL(value, token);
      if (res.error) {
        setError(res.error);
      } else {
        const { id } = res;
        onClose();
        window.location.href = `/recipes/${id}`;
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}
      margin="medium"
    >
      <Card background="background-front" pad="medium" overflow="auto">
        <Box direction="row" justify="end">
          <Button secondary icon={<Close />} onClick={onClose} />
        </Box>
        <Heading margin="small" level="3" as="h1">
          Skapa recept
        </Heading>
        <Box gap="medium">
          <Form
            validate="blur"
            value={formValues}
            messages={{
              required: "This is a required field.",
            }}
            onChange={(nextValue) => onChange(nextValue)}
            onSubmit={({ value, touched }) => onSubmit({ value, touched })}
            method="post"
          >
            <FormField
              name="url"
              htmlFor="url"
              type="text"
              plain="true"
              label="URL"
            >
              <TextInput
                type="text"
                placeholder="https://example.com/recipe"
                name="url"
                plain="true"
              />
            </FormField>
            {error && (
              <Text color="status-error" as="p">{error}</Text>
            )}
            <Box>
              <Button secondary label="Importera och skapa recept" type="submit" />
            </Box>
          </Form>
          <Button primary label="Skapa nytt recept" onClick={onCreateNewRecipe} />
        </Box>
      </Card>
    </Layer>
  );
};

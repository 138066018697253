
export const IMAGE_TYPES = {
    SMALL : "small",
    LARGE : "large"
}

const DEFAULT_IMAGE = "/gurkan_default.png";

export function getImageUrl(images, size) {
    let imagesBySize = images.filter(image => image.imageType === size)

    if (imagesBySize.length > 0) {
        return imagesBySize[0].url;
    }

    return DEFAULT_IMAGE;
}

import { useEffect, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { register, Hanko } from "@teamhanko/hanko-elements";
import { Box } from "grommet";
import { Page } from "../shared/Page";
import { getUser, createUser } from "./service";

const hankoApi = process.env.REACT_APP_HANKO_API_URL;

export default function HankoAuth() {
  const navigate = useNavigate();
  const hanko = useMemo(() => new Hanko(hankoApi), []);
  const [searchParams] = useSearchParams();

  const createUserIfNotExists = async (userID) => {
    try {
      const user = await getUser(userID)
      if (user) {
        return
      }
      await createUser(userID, "Användare")
    } catch (error) {
      console.log("error", error)
    }
  }

  const redirectAfterLogin = useCallback(async (userID) => {
    await createUserIfNotExists(userID);
    const redirectTo = searchParams.get("redirectTo");
    navigate(redirectTo ?? "/");
  }, [navigate, searchParams]);

  useEffect(
    () =>
      hanko.onAuthFlowCompleted((session) => {
        redirectAfterLogin(session.userID);
      }),
    [hanko, redirectAfterLogin]
  );

  useEffect(() => {
    register(hankoApi).catch((error) => {
      // handle error
    });
  }, []);

  return (
    <Page>
      <Box align="center">
        <hanko-auth />
      </Box>
    </Page>
  );
}

import React from "react";
import { useSessionData } from "../auth/useSessionData";
import Home from "./Home";
import HomeAuthorized from "./HomeAuthorized";

const HomePage = () => {
  const session = useSessionData();

  return session && session.isValid ? (
    <HomeAuthorized />
  ) : (
    <Home />
  );
};

export default HomePage;


import React, { useState, useEffect } from "react";
import { getShoppinglist, updateListItem } from "./service";
import { useLoaderData } from "react-router-dom";
import { Box, CheckBoxGroup, Heading, Text } from "grommet";
import { Page } from "../shared/Page";
import Responsive from "../util/responsive";
import styled from "styled-components";
import theme from "../theme";

const StyledCheckBoxGroup = styled(CheckBoxGroup)`
  label {
    width: 100%;
    align-items: flex-start;
  }
  label > :nth-child(2) {
    border-bottom: 1px solid ${theme.global.colors['brand']};
  }
`;

export async function loader({ params }) {
  return getShoppinglist(params.id);
}

const Shoppinglist = (props) => {
  const shoppinglist = useLoaderData();
  const [value, setValue] = useState([])

  useEffect(() => {
    if (shoppinglist.items) {
      setValue(shoppinglist.items.map((element) => {
        if (element.isMarked) {
          return element.ingredient.id
        }
      }))
    }
  }, [shoppinglist])

  const onItemClicked = async ({option, value}) => {
    await updateListItem(shoppinglist.id, option.ingredient.id, !option.isMarked, option.amount)
    await getShoppinglist(shoppinglist.id)
    setValue(value)
  }

  return (
    <Page width="large">
      <Heading level="3" as="h1">{shoppinglist.name}</Heading>
      {shoppinglist.items != null &&
        <Box pad={{vertical: "medium"}}>
          <Responsive>
            {({ isSmall }) => (
              <StyledCheckBoxGroup
                onChange={onItemClicked}
                options={mapItems(shoppinglist)}
                value={value}
                valueKey="id"
                gap={isSmall ? "large" : "medium"}
              />
            )}
          </Responsive>
        </Box>
      }
      {shoppinglist.items == null && (
        <>
          <Heading level="4" as="h2">Listan är tom.</Heading>
          <Text>Lägg till ett recept till den här listan för att se varorna här.</Text>
        </>
      )}
    </Page>

  );
};

const mapItems = (shoppinglist) => {
  return shoppinglist.items.map((element) => {
    return {
      ...element,
      label: (
        <Box fill direction="row" justify="between">
          <Text>{element.ingredient.name}</Text>
          <Box flex={{ shrink: 0 }}>{`${element.unit.suffix.length ? "" : "× "}${element.amount} ${element.unit.suffix}`}</Box>
        </Box>
      ),
      id: element.ingredient.id,
    };
  });
}

export default Shoppinglist;


import { BASE_URL, CommonHeaders } from "../util/service";

const createUser = async (id, displayName) => {
  try {
    const res = await fetch(BASE_URL + "user", {
      method: "POST",
      headers: CommonHeaders(),
      body: JSON.stringify({ id: id, display_name: displayName }),
    })
    const jsonUser = await res.json()

    if (!res.ok) {
      throw new Error("Failed to create user")
    }
    const user = jsonUser.user
    return user;
  } catch (error) {
    console.log("error", error)
  }
}

const getUser = async (id) => {
  try {
    const res = await fetch(BASE_URL + "user/" + id, {
      method: "GET",
      headers: CommonHeaders(),
    })
    const jsonUser = await res.json()

    if (!res.ok) {
      throw new Error("Failed to get user")
    }
    const user = jsonUser.user
    return user;
  } catch (error) {
    console.log("error", error)
    return null;
  }
}

const updateUser = async (id, displayName) => {
  try {
    const res = await fetch(BASE_URL + "user/" + id, {
      method: "PUT",
      headers: CommonHeaders(),
      body: JSON.stringify({ display_name: displayName }),
    })
    const jsonUser = await res.json()

    if (!res.ok) {
      throw new Error("Failed to update user")
    }
    const user = jsonUser.user
    return user;
  } catch (error) {
    console.log("error", error)
  }
}

const deleteUser = async (id) => {
  try {
    const res = await fetch(BASE_URL + "user/" + id, {
      method: "DELETE",
      headers: CommonHeaders(),
    })

    if (!res.ok) {
      throw new Error("Failed to delete user")
    }
  } catch (error) {
    console.log("error", error)
  }
}

export { createUser, getUser, updateUser, deleteUser }